import axios from 'axios'

const baseURL = process.env.VUE_APP_URL

// process.env.VUE_APP_URL

export const axiosComponents = axios.create({
  baseURL: baseURL
});



