import Vue from "vue";
import App from "./App.vue";
import "libs/js/index.ts";
import "./assets/css/style.scss";
import store from "./stores/store";

// @ts-ignore
Vue.config.productionTip = false;
new Vue({
  store,
  render: (h) => h(App),
}).$mount("#app");
