import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
type DeviceType = "phone" | "tablet" | "laptop" | "monitor";

interface StateType {
  sizes: {
    [key in DeviceType]: number;
  };
  activeIcon: DeviceType;
}

export default new Vuex.Store<StateType>({
  state: {
    activeIcon: "monitor",
    sizes: {
      phone: 368,
      tablet: 726,
      laptop: 1214,
      monitor: 1600,
    },
  },
  mutations: {
    updateSize(state, { device, size }: { device: DeviceType; size: number }) {
      state.sizes[device] = size;
    },
    updateActiveIcon(state, { device }: { device: DeviceType }) {
      state.activeIcon = device;
    },
  },
  actions: {
    setSize({ commit }, { device, size }) {
      commit("updateSize", { device, size });
    },
    setActiveIcon({ commit }, device: DeviceType) {
      commit("updateActiveIcon", { device });
    },
  },
  getters: {
    getSizes: (state): StateType["sizes"] => state.sizes,
    getSizeByDevice:
      (state) =>
      (device: DeviceType): number => {
        return state.sizes[device];
      },
    getActiveIcon: (state) => state.activeIcon,
  },
});
