<template>
  <div class="size-controller">
    <div class="screen-size-input">
      {{ screenSize.toFixed(2).replace(/\.?0+$/, "") }} px /
      {{
        parseFloat(screenSize / 16)
          .toFixed(2)
          .replace(/\.?0+$/, "")
      }}
      em
    </div>
    <div class="icon-container">
      <div
        v-for="(icon, index) in icons"
        :key="index"
        :class="{ active: activeIcon === icon.name }"
        @click="selectIcon(icon.name)"
        class="icon"
      >
        <img
          :src="require(`../assets/images/icons/${icon.file}`)"
          :alt="`${icon.name} Icon`"
        />
        <div class="tooltip">{{ icon.size }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      icons: [
        { name: "phone", file: "phone.svg", size: "Small" },
        { name: "tablet", file: "tablet.svg", size: "Medium" },
        { name: "laptop", file: "laptop.svg", size: "Large" },
        { name: "monitor", file: "monitor.svg", size: "Fullscreen" },
      ],
      activeIcon: "monitor",
    };
  },

  computed: {
    ...mapGetters(["getSizeByDevice"]),
    screenSize: {
      get() {
        return this.getSizeByDevice(this.activeIcon);
      },
      set(value) {
        this.$store.dispatch("setSize", {
          device: this.activeIcon,
          size: value,
        });
      },
    },
  },

  methods: {
    selectIcon(iconName) {
      this.activeIcon = iconName;
      this.$store.dispatch("setActiveIcon", iconName);
    },
  },
};
</script>
