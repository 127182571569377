<script>
import MenuItem from "./components/navigation/MenuItem.vue";
import DescriptionBox from "./components/DescriptionBox.vue";
import { axiosComponents } from "../axios";
import ScreenSizeSelector from "./components/ScreenSizeSelector.vue";
import DynamicIframeLoader from "./components/DynamicIframeLoader.vue";

export default {
  components: {
    DynamicIframeLoader,
    MenuItem,
    DescriptionBox,
    ScreenSizeSelector,
  },

  data() {
    return {
      componentData: null,
      componentPages: [],
    };
  },

  methods: {},
  async mounted() {
    this.componentPages = await axiosComponents.get(
      `/menus/1/?nested&populate=deep,3`
    );
    const pageSlug = window.location.pathname.split("/").pop();
    const response = await axiosComponents.get(
      `/component-pages?filters[slug][$eq]=${pageSlug}`
    );
    if (response.data.data.length === 1) {
      this.componentData = response.data.data[0].attributes;
    } else {
      this.componentData = null;
    }
  },
};
</script>

<template>
  <div id="app">
    <main role="d-flex">
      <div class="row w-100 m-0">
        <div class="col-2 pe-0 bg-primary">
          <aside class="main-left-panel">
            <div class="logo-place p-4">
              <div class="logo">
                <img
                  width="74"
                  src="@/assets/images/logo_PRE-color.svg"
                  title="Pražská energetika, a. s. - úvodní stránka"
                  alt="logo PRE"
                  class="logo__picture"
                />
              </div>
              <p>Design systém</p>
            </div>
            <div class="menu-items">
              <nav class="menu">
                <ul v-if="componentPages?.data?.data.attributes.items.data">
                  <template
                    v-for="menuItem in componentPages.data.data.attributes.items
                      .data"
                  >
                    <MenuItem :data="menuItem" v-bind:key="menuItem.id" />
                  </template>
                </ul>
              </nav>
            </div>
            <ScreenSizeSelector />
          </aside>
        </div>
        <div
          class="col-10 px-0 bg-gray-100 justify-content-center"
          style="height: auto; display: flex; flex-direction: column"
        >
          <DescriptionBox
            v-if="componentData?.content"
            :description="componentData.content"
          />
          <div class="subtitle-comp">
            <h5 class="mb-0 p-4 text-white">Ukázka komponenty:</h5>
          </div>
          <DynamicIframeLoader
            :componentData="componentData"
            style="flex-grow: 1"
          />
        </div>
      </div>
    </main>
  </div>
</template>
