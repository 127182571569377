<template>
  <div v-if="!componentData || !componentData.url" class="iframe-placeholder">
    Vyberte komponentu z levého menu.
  </div>
  <div v-else :class="['iframe-wrapper', fullScreenStyle]">
    <div v-show="isLoading" class="iframe-placeholder"><LoadingSpinner /></div>
    <div v-show="!isLoading" :class="['iframe-w', fullScreenStyle]">
      <iframe
        :class="['iframe-cls', fullScreenStyle]"
        ref="iframe"
        :src="iframeSrc"
        :style="{ width: iframeWidth + 'px' }"
      ></iframe>
      <div
        class="overlay"
        v-show="isResizing"
        @mousemove="doResize"
        @mouseup="stopResize"
      ></div>
      <div
        v-if="isFullScreen === false"
        class="resizer"
        @mousedown="initResize"
        @touchstart="initResize"
      >
        |
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { axiosComponents } from "../../axios";
import callAssets from "../helpers/callAssets";
import LoadingSpinner from "../components/LoadingSpinner.vue";

export default {
  components: {
    LoadingSpinner,
  },

  props: {
    componentData: {
      type: Object,
      default: () => ({ url: null }),
      validator: (prop) =>
        prop === null ||
        (typeof prop === "object" && typeof prop.url === "string"),
    },
  },

  data() {
    return {
      isFullScreen: true,
      fullScreenStyle: "",
      iframeSrc: "about:blank",
      isResizing: false,
      isLoading: true,
      startWidth: 0,
      startX: 0,
      minWidth: 223,
      maxWidth: 1600,
    };
  },

  computed: {
    ...mapGetters(["getActiveIcon", "getSizes"]),

    iframeWidth() {
      if (this.getActiveIcon == "monitor") {
        this.setFullScreenOn();
        return true;
      } else {
        this.setFullScreenOff();
        return this.getSizes[this.getActiveIcon];
      }
    },
  },

  watch: {
    componentData: {
      handler: "loadIframeContent",
      deep: true,
    },
  },

  methods: {
    bindIFrameMousemove(iframe) {
      iframe.contentWindow.addEventListener("mousemove", function (event) {
        var clRect = iframe.getBoundingClientRect();
        var evt = new CustomEvent("mousemove", {
          bubbles: true,
          cancelable: false,
        });

        evt.clientX = event.clientX + clRect.left;
        evt.clientY = event.clientY + clRect.top;

        iframe.dispatchEvent(evt);
        this.doResize;
      });
    },

    bindIFrameMouseup(iframe) {
      const handleMouseup = () => {
        var evt = new CustomEvent("mouseup", {
          bubbles: true,
          cancelable: false,
        });

        iframe.dispatchEvent(evt);
        this.stopResize;
      };

      iframe.contentWindow.addEventListener("mouseup", handleMouseup);
    },

    initResize(event) {
      this.isResizing = true;
      this.startX = event.clientX;
      this.startWidth = parseInt(
        document.defaultView.getComputedStyle(this.$refs.iframe).width,
        10
      );

      const iframeElement = document.querySelector(".iframe-cls");
      this.bindIFrameMousemove(iframeElement);
      this.bindIFrameMouseup(iframeElement);

      document.addEventListener("mousemove", this.doResize);
      document.addEventListener("mouseup", this.stopResize);
    },

    doResize(event) {
      if (!this.isResizing) return;
      let clientX = event.clientX;
      let delta = clientX - this.startX;
      let newWidth = this.startWidth + delta * 2;

      if (newWidth < this.minWidth) newWidth = this.minWidth;
      else if (newWidth > this.maxWidth) newWidth = this.maxWidth;

      let newLeft = (this.startWidth - newWidth) / 2 + "px";

      this.updateIframeWidthInStore(newWidth);
      this.$refs.iframe.style.width = newWidth + "px";
      this.$refs.iframe.style.left = newLeft;
    },

    stopResize() {
      this.isResizing = false;
      document.removeEventListener("mousemove", this.doResize);
      document.removeEventListener("mouseup", this.stopResize);
    },

    updateIframeWidthInStore(width) {
      this.$store.dispatch("setSize", {
        device: this.getActiveIcon,
        size: width,
      });
    },

    setFullScreenOff() {
      this.fullScreenStyle = " ";
      this.isFullScreen = false;
    },

    setFullScreenOn() {
      this.fullScreenStyle = "iframe-fullscreen";
      this.isFullScreen = true;
    },

    async loadIframeContent() {
      if (!this.componentData || !this.componentData.url) {
        console.error("componentData or componentData.url is undefined");
        return;
      }
      this.isLoading = true;
      try {
        let component = await axiosComponents(this.componentData.url);
        if (component) {
          const componentWithAssets = await callAssets(component.data);
          const doc = this.$refs.iframe?.contentWindow?.document;
          if (doc) {
            doc.open();

            doc.writeln(componentWithAssets);
            this.setHeadElements(doc);
            this.setScriptElements(doc);
            this.setMetaElements(doc);

            doc.close();
          }
        }
      } catch (e) {
        console.error("Failed to load content:", e);
      } finally {
        this.isLoading = false;
      }
    },

    setHeadElements(doc) {
      if (document?.head !== undefined) {
        Array.from(
          document?.head?.querySelectorAll('link[rel="stylesheet"], style')
        ).forEach((element) => {
          doc.head.appendChild(element.cloneNode(true));
        });
      }
    },

    setScriptElements(doc) {
      Array.from(document.head.querySelectorAll("script")).forEach(
        (element) => {
          const script = document.createElement("script");
          script.src = element.src;
          script.type = element.type;
          script.async = false;
          doc.head.appendChild(script);
        }
      );
    },

    setMetaElements(doc) {
      const meta = document.createElement("meta");
      meta.setAttribute("charset", "UTF-8");
      doc.head.appendChild(meta);
    },
  },

  mounted() {
    if (this.iframeWidth === true) {
      this.setFullScreenOn();
    }

    this.loadIframeContent();
  },
};
</script>
