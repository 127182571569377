<template>
  <div class="accordion" id="accordionDescription">
    <div class="accordion-item border-0 border-bottom-1">
      <h2 class="accordion-header title-comp" id="headingOne">
        <button
          class="accordion-button p-0 px-2 pe-3 d-flex align-items-center"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          <h5 class="m-0 p-4 text-white">Popis komponenty:</h5>
        </button>
      </h2>
      <div
        id="collapseOne"
        class="accordion-collapse collapse show"
        aria-labelledby="headingOne"
        data-bs-parent="#accordionDescription"
      >
        <div class="accordion-body bg-white p-4">
          <span v-html="description"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DescriptionBox",
  props: {
    description: {
      type: String,
      required: false,
    },
  },
};
</script>
