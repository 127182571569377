
import Vue from "vue";

export default Vue.extend({
  name: "MenuItem",
  props: {
    data: {
      type: Object,
      default: () => {},
      required: true,
    },
    parentUrl: {
      type: String,
      default: "",
      required: false,
    },
  },

  data() {
    return {
      showChildrenMenu: false,
    };
  },

  methods: {
    showContentAndChildrenMenu() {
      this.showChildrenMenu = !this.showChildrenMenu;
      const urlAfterSlash = this.urlAddition;
      window.location.href = this.parentUrl + "/" + urlAfterSlash;
    },

    openMenu() {
      this.showChildrenMenu = true;
    },

    closeMenu() {
      this.showChildrenMenu = false;
    },
  },

  computed: {
    urlAddition(): any {
      return this.data?.attributes.children.data.length
        ? this.data.attributes.url.replace("/", "")
        : this.data.attributes.page_relation.data?.attributes.slug || "";
    },
  },

  async mounted() {
    if (
      window.location.href.indexOf("/" + this.urlAddition + "/") > -1 ||
      window.location.href.indexOf("/" + this.urlAddition) > -1
    ) {
      this.showChildrenMenu = true;
    }
  },
});
