export default async (string) => {
  let stringMatch = string.matchAll(
    /\.\.?\/[^\n"?:*<>|]+\.[A-z0-9]+/g
  );
  if (!stringMatch) return string;
  stringMatch = [...stringMatch];
  stringMatch = stringMatch.map((item) => {
    return item[0];
  });
  for (const url of stringMatch) {

    try {

      const module = await import(
        `../../assets/` + url.replace('../../assets/', '')
      );
      string = string.replaceAll(url, module.default);
    } catch (error) {
      console.log(error)
    }
  }
  return string;
};
